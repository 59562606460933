import isMobile from './isMobile';
import getContainerSize from './getContainerSize';

export { isMobile, getContainerSize };

export default {
  isMobile,
  getContainerSize,
};



const firstUtm = (utm_medium, utm_source, utm_campaign, utm_offer, utm_term, utm_audience) => {
  if(utm_medium && !localStorage.first_utm_medium) {
      localStorage.setItem('first_utm_medium', utm_medium)
      localStorage.setItem('first_utm_timestamp_medium', new Date())
  }
  if(utm_source && !localStorage.first_utm_source) {
      localStorage.setItem('first_utm_source', utm_source)
      localStorage.setItem('first_utm_timestamp_source', new Date())
  }
  if(utm_campaign && !localStorage.first_utm_campaign) {
      localStorage.setItem('first_utm_campaign', utm_campaign)
      localStorage.setItem('first_utm_timestamp_campaign', new Date())
  }
  if(utm_offer && !localStorage.first_utm_offer) {
      localStorage.setItem('first_utm_offer', utm_offer)
      localStorage.setItem('first_utm_timestamp_offer', new Date())
  }
  if(utm_term && !localStorage.first_utm_term) {
      localStorage.setItem('first_utm_term', utm_term)
      localStorage.setItem('first_utm_timestamp_term', new Date())
  }
  if(utm_audience && !localStorage.first_utm_audience) {
    localStorage.setItem('first_utm_audience', utm_audience)
    localStorage.setItem('first_utm_timestamp_audience', new Date())
  }
}

export const getURL = () => {
  let feURL = window.location.href;
  localStorage.setItem('visited_url', feURL)
}

export const getEloquaGUID = (value) => {
  const initialElqGUIDValue = value;
  console.log(initialElqGUIDValue);
}

export const getParams = () => {
  let params = new URLSearchParams(document.location.search.substring(1));
  let utm_cookie_status = params.get("utm_vip");
  let utm_audience = params.get("utm_audience");
  let utm_medium = params.get("utm_medium");
  let utm_source = params.get("utm_source");
  let utm_campaign = params.get("utm_campaign");
  let utm_offer = params.get("utm_offer");
  let utm_term = params.get("utm_term");

  // check and set UTM Params if present
  firstUtm(utm_medium, utm_source, utm_campaign, utm_offer, utm_term, utm_audience);

  if(utm_cookie_status) {
    localStorage.setItem('utm_cookie_status', utm_cookie_status)
    localStorage.setItem('utm_timestamp_cookie_status', new Date())
  }

  if(utm_audience) {
    localStorage.setItem('utm_audience', utm_audience)
    localStorage.setItem('utm_timestamp_audience', new Date())
  }

  if(utm_medium) {
    localStorage.setItem('utm_medium', utm_medium)
    localStorage.setItem('utm_timestamp_medium', new Date())
  }

  if(utm_source) {
    localStorage.setItem('utm_source', utm_source)
    localStorage.setItem('utm_timestamp_source', new Date())
  }

  if(utm_campaign) {
    localStorage.setItem('utm_campaign', utm_campaign)
    localStorage.setItem('utm_timestamp_campaign', new Date())
  }

  if(utm_offer) {
    localStorage.setItem('utm_offer', utm_offer)
    localStorage.setItem('utm_timestamp_offer', new Date())
  }

  if(utm_term) {
    localStorage.setItem('utm_term', utm_term)
    localStorage.setItem('utm_timestamp_term', new Date())
  }
}
